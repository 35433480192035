import "primereact/resources/themes/vela-green/theme.css"
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "primeflex/primeflex.css";
import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import AppRouter from "./router";
import ScrollToTop from './components/ScrollToTop';
import { Toast } from "primereact/toast"
import { createContext, useRef } from "react";

export const ToastContext = createContext()
const typeMapping = {
  "s": "success",
  "i": "info",
  "w": "warning",
  "e": "error"
}
const labelMapping = {
  "s": "Success",
  "i": "Info",
  "w": "Warning",
  "e": "Error"
}

function App() {
  const toast = useRef();
  const showToast = (type, msg) => {
    toast.current.show({ severity: typeMapping[type], summary: labelMapping[type], detail: msg, life: 3000 });
  }
  return (
    <Router>
      <Toast ref={toast} />
      <ScrollToTop />
      <ToastContext.Provider value={showToast}>
        <AppRouter />
      </ToastContext.Provider>
    </Router>

  );
}

export default App;
