import React from "react";
// import Typography from "@mui/material/Typography";
const Loading = () => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
        color: "#81C784",
        backgroundColor: "#17212f",
      }}
    >
      Loading...
    </div>
  );
};
export default Loading;
