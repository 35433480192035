import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
import Loading from "./loading";
import "@lottiefiles/lottie-player";
const Home = loadable(() => import("./website/home"), {
  fallback: <Loading />,
});
const Advertiser = loadable(() => import("./website/advertiser"), {
  fallback: <Loading />,
});
const Publisher = loadable(() => import("./website/publisher"), {
  fallback: <Loading />,
});
function AppRouter() {
  const [redirectUrl, setRedirectUrl] = useState("/");

  return (
    <Routes>
      {/* <Route path="/" exact element={<Website />} /> */}
      {/* <Route path="/tnc" element={TNC} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/advertiser" element={<Advertiser />} />
      <Route path="/publisher" element={<Publisher />} />
      <Route path="*" element={<Navigate to={redirectUrl} replace={true} />} />
    </Routes>
  );
}

export default AppRouter;
